//import logo from './logo.svg';
import './App.css';
import axios, {AxiosResponse} from 'axios';
import React, { Component} from 'react';
import Status1 from './download1.svg'
import Status2 from './download2.svg'
import Status3 from './download3.svg'
import Status4 from './download4.svg'
import Status5 from './download5.svg'

class StatusPage extends Component{
  constructor(props){
    super(props);
    
    
    this.state = {
      dlStatus: "Requesting document...",
      statusToken: "",
      downloadToken: "",
      downloadUrl: "",
      activeRequest: 0,
      baseUrl: "https://8qjltl81mf.execute-api.ca-central-1.amazonaws.com/dev/",
      intervalId: 0
    }
    
    this.handleClick = this.handleClick.bind(this);
  }

  





  handleClick() {
    console.log(this.state);
    if (this.state != null && this.state["activeRequest"] != 0) return;
    this.setState((state)=>{return {activeRequest: 1}});
    if(this.state["statusToken"] && !this.state["downloadToken"]){
      axios.get(this.state["baseUrl"] + 'status',{headers: {'Authorization': this.state.statusToken}})
    .then(res=>{
      const results = res.data;
      console.log(results);
      this.setState((state) => {return { dlStatus: results.DocumentStatus, downloadToken: results.RetrievalToken, activeRequest: 0};})});
    } 
    if(this.state["downloadToken"]){
      clearInterval(this.state["intervalId"]);
      this.setState((state) => {return {dlStatus: "Downloading..."}})
      axios({
        url: this.state["baseUrl"] + 'retrievedocument',
        method: 'GET',
        responseType: 'blob',
        headers: {'Authorization': this.state["downloadToken"], 'Accept': 'application/pdf'} 
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'statement.pdf');
        document.body.appendChild(link);
        link.click();
        this.setState((state) => {return {dlStatus: "Downloaded."}})
      });
    }
  }
  componentDidMount(){
    const params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    });
    let value = params.token; 
    axios.get(this.state["baseUrl"] + 'requestreport',{headers: {'Authorization': value}})
    .then(res=>{
      const results = res.data.replaceAll('"', '');
      console.log("in componentdidmount");
      var _intervalId = setInterval(this.handleClick, 5000)
      this.setState((state) => {return {statusToken: results, dlStatus: "Requesting...", intervalId: _intervalId  };})});
      
  }
  render(){
    return(
      <div>
      <ImageSwap dlStatus={this.state.dlStatus} />
      </div>
    )
  }
}
class ImageSwap extends Component{

  constructor(props){
    super(props);
    this.possibleStates = {
      "": Status1,
      "Requesting document...": Status1,
      "Requesting...": Status1,
      "Queued": Status2,
      "Processing": Status3,
      "Complete": Status4,
      "Downloading...": Status4,
      "Downloaded.": Status5,
      "Error": Status5
    }
    this.state={currentStatus: Status1}
  }
 componentDidUpdate(prevProps){
   console.log("image componentDidUpdate with prevprops: " + prevProps.dlStatus + " and props: " + this.props.dlStatus);
   if(prevProps.dlStatus !== this.props.dlStatus){
     console.log("Updating image state");
     this.setState((state)=>{return {currentStatus: this.possibleStates[this.props.dlStatus]};});
   }
 }
  render(){
    return (<div><img src={this.state.currentStatus}/><span>{this.props.dlStatus}</span></div>)
  }
}
class App extends Component{
  render(){
    return(
      <StatusPage />
    )
  }
}
export default App;

